import { ISocialMedia } from 'models/Home/ISocialMedia'

export interface TeamCardProps {
  image: string
  name: string
  role: string
  bio: any[]
  socialLinks: ISocialMedia[]
}

export const useTeamCard = (props: TeamCardProps) => {
  return {
    ...props,
  }
}
