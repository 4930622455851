import getMonthName from 'utils/getMonthName'
import { useCustomTranslation } from 'hooks/useCustomTranslation'

export interface PostCardProps {
  title: string
  description: string
  author: string
  category: string
  image: string
  authorImage: string | null
  url: string
  date: string
  isFeatured?: boolean
  isAr: boolean
}

export const usePostCard = (props: PostCardProps) => {
  const { date, isAr } = props

  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language

  const splittedDate = date.split('-')
  let day = ''
  let monthAndYear = date

  if (splittedDate.length > 1) {
    day = splittedDate[2]
    monthAndYear = `${getMonthName(+splittedDate[1] - 1, currentLanguage)}, ${splittedDate[0]}`
  }

  return {
    ...props,
    day,
    monthAndYear,
    isAr,
    t,
  }
}
