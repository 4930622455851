import React, { Fragment } from 'react'
import { useAbout, AboutProps } from './useAbout'
import { Link } from 'react-router-dom'
import blockContentToHtml from '@sanity/block-content-to-html'
import styles from './About.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'

const About: React.FC<AboutProps> = (props: AboutProps) => {
  const { aboutData, isAr, t } = useAbout(props)

  return (
    <section id='about' className={styles.about}>
      <TitleSection title={t('navAboutUs')} />
      <div className='container'>
        {aboutData && aboutData.length ? (
          <div key={aboutData[0].id} className='row align-items-center'>
            <div className='col-lg-6'>
              <div className={styles['about-content']}>
                {aboutData &&
                  aboutData.length &&
                  aboutData.map((item, index) => (
                    <div key={`about-data-${item.id}-${index}`}>
                      <h2 className={styles.title}>
                        {isAr ? item.titleAr : item.titleEn}
                      </h2>
                      {item.subTitleAr ? (
                        <h3 className={styles['sub-title']}>
                          {isAr ? item.subTitleAr : item.subTitleEn}
                        </h3>
                      ) : (
                        ''
                      )}
                      <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: isAr
                            ? Array.isArray(item.descriptionAr)
                              ? blockContentToHtml({
                                  blocks: item.descriptionAr,
                                })
                              : `<p>${item.descriptionAr}</p>`
                            : Array.isArray(item.descriptionEn)
                              ? blockContentToHtml({
                                  blocks: item.descriptionEn,
                                })
                              : `<p>${item.descriptionEn}</p>`,
                        }}
                      ></div>
                    </div>
                  ))}
              </div>
              <div className='d-flex justify-content-center justify-content-lg-start mb-4 mb-lg-0'>
                <Link to='/Contact' className='btn btn-primary px-5 py-2'>
                  {t('navContactUs')}
                </Link>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className={styles['about-image']}>
                <img
                  src={aboutData[0].image}
                  alt='about'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        ) : (
          <Fragment />
        )}
      </div>
    </section>
  )
}

export default About
