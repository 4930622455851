import React, { memo, Fragment } from 'react'
import { useValueCard, ValueCardProps } from './useValueCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './ValueCard.module.scss'

const ValueCard: React.FC<ValueCardProps> = (props: ValueCardProps) => {
  const { title, description, icon, iconPrefix } = useValueCard(props)

  return (
    <div className={styles['value-card']}>
      <div className={styles['icon-container']}>
        {icon && iconPrefix ? (
          <FontAwesomeIcon
            icon={[(iconPrefix as IconPrefix) || 'fas', icon as IconName]}
            className={styles.icon}
          />
        ) : (
          <Fragment />
        )}
      </div>
      <div className={styles.content}>
        <h4>{title}</h4>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: Array.isArray(description)
              ? blockContentToHtml({
                  blocks: description,
                })
              : `<p>${description}</p>`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default memo(ValueCard)
