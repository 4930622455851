import React from 'react'
import { useImagesGallery, ImagesGalleryProps } from './useImagesGallery'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Parallax } from 'swiper/modules'
import styles from './ImagesGallery.module.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const ImagesGallery: React.FC<ImagesGalleryProps> = (
  props: ImagesGalleryProps
) => {
  const { gallery } = useImagesGallery(props)

  return (
    <div className='container'>
      <section className={styles['gallery-container']}>
        <Swiper
          dir='rtl'
          navigation
          loop
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          style={
            {
              '--swiper-navigation-color': '#035373',
              '--swiper-pagination-color': '#035373',
            } as React.CSSProperties
          }
          parallax={true}
          modules={[Navigation, Pagination, Parallax]}
          className={styles.swiper}
          data-swiper-parallax='-23%'
        >
          {gallery &&
            gallery.length &&
            gallery.map((slider, index) => (
              <SwiperSlide className={styles.slide} key={`slider-${index}`}>
                <img
                  src={slider}
                  alt='slider image'
                  className={styles.background}
                  loading={index ? 'lazy' : 'eager'}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
    </div>
  )
}

export default ImagesGallery
