import React, { memo, Fragment } from 'react'
import { useGoalCard, GoalCardProps } from './useGoalCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './GoalCard.module.scss'

const GoalCard: React.FC<GoalCardProps> = (props: GoalCardProps) => {
  const { title, description, icon, iconPrefix } = useGoalCard(props)

  return (
    <div className={styles['goal-card']}>
      {icon && iconPrefix ? (
        <FontAwesomeIcon
          icon={[(iconPrefix as IconPrefix) || 'fas', icon as IconName]}
          className={styles.icon}
        />
      ) : (
        <Fragment />
      )}
      <h4>{title}</h4>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: Array.isArray(description)
            ? blockContentToHtml({
                blocks: description,
              })
            : `<p>${description}</p>`,
        }}
      ></div>
    </div>
  )
}

export default memo(GoalCard)
