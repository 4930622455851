import { IContactSocials } from 'models/Contact/IContactSocials'
import { ISocialMedia } from 'models/Home/ISocialMedia'
import { TranslationKeys } from 'types/translationKeys'

export interface AboutProps {
  isAr: boolean
  socialMedia: ISocialMedia[] | null
  contactSocials: IContactSocials[] | null
  t: (key: TranslationKeys) => string
}

export const useAbout = (props: AboutProps) => {
  return {
    ...props,
  }
}
