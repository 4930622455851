import { createSlice } from '@reduxjs/toolkit'
import { IContactSocials } from 'models/Contact/IContactSocials'

export interface ContactState {
  isLoading: boolean
  isSendContactMessageSuccess: boolean
  isSendContactMessageFailed: boolean
  isGetContactSocialsSuccess: boolean
  contactSocials: IContactSocials[] | null
  contactMessageResponse: any | null
}

const initialState: ContactState = {
  isLoading: false,
  isSendContactMessageSuccess: false,
  isSendContactMessageFailed: false,
  isGetContactSocialsSuccess: false,
  contactSocials: null,
  contactMessageResponse: null,
}

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getContactSocials(state, action) {
      state.isLoading = false
      state.isGetContactSocialsSuccess = true
      state.contactSocials = action.payload.contactSocials.result
    },
    sendContactMessage(state, action) {
      state.isLoading = false
      state.isSendContactMessageSuccess = true
      state.isSendContactMessageFailed = false
      state.contactMessageResponse = action.payload.contactMessageResponse
    },
  },
})

export const { apiRequest, apiFailure, getContactSocials, sendContactMessage } =
  contactSlice.actions

export default contactSlice.reducer
