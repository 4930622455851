import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import IOurTeamResponse from 'models/Team/IOurTeam'

export const teamService = {
  getOurTeam: async (): Promise<IOurTeamResponse> => {
    try {
      const response: IOurTeamResponse = await makeRequest(
        'GET',
        ENDPOINTS.Team.GetOurTeam
      )

      return response
    } catch (error) {
      throw new Error(`Get our team data failed: ${error}`)
    }
  },
}
