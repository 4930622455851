import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface ValuesProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useValues = (props: ValuesProps) => {
  const valuesData = useSelector((state: RootState) => state.about.values)

  return {
    ...props,
    valuesData,
  }
}
