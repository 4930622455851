import { createSlice } from '@reduxjs/toolkit'
import { IAbout } from 'models/About/IAbout'

export interface AboutState {
  isLoading: boolean
  isGetAboutSuccess: boolean
  aboutData: IAbout[] | null
  whoWeAre: IAbout[] | null
  services: IAbout[] | null
  values: IAbout[] | null
  goals: IAbout[] | null
}

const initialState: AboutState = {
  isLoading: false,
  isGetAboutSuccess: false,
  aboutData: null,
  whoWeAre: null,
  services: null,
  values: null,
  goals: null,
}

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getAbout(state, action) {
      state.isLoading = false
      state.isGetAboutSuccess = true
      state.aboutData = action.payload.aboutData.result

      state.whoWeAre = action.payload.aboutData.result.filter(
        (item: IAbout) => item.infoSection.toLowerCase() === 'whoweare'
      )

      state.services = action.payload.aboutData.result.filter(
        (item: IAbout) => item.infoSection.toLowerCase() === 'services'
      )

      state.values = action.payload.aboutData.result.filter(
        (item: IAbout) => item.infoSection.toLowerCase() === 'values'
      )

      state.goals = action.payload.aboutData.result.filter(
        (item: IAbout) => item.infoSection.toLowerCase() === 'goals'
      )
    },
  },
})

export const { apiRequest, apiFailure, getAbout } = aboutSlice.actions

export default aboutSlice.reducer
