import React from 'react'
import { useLinks, LinksProps } from './useLinks'
import { Link } from 'react-router-dom'
import 'fontawesome'
import styles from './Links.module.scss'

const Links: React.FC<LinksProps> = (props: LinksProps) => {
  const { t } = useLinks(props)

  return (
    <div className={`col-lg-6 col-xl-3 ${styles['footer-links']}`}>
      <h4 className={styles.title}>{t('WebsitePages')}</h4>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link to='/' className={styles.link}>
            {t('navHome')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link to='/About' className={styles.link}>
            {t('navAboutUs')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link to='/OurBranches' className={styles.link}>
            {t('navOurBranches')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link to='/OurTeam' className={styles.link}>
            {t('navOurTeam')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link to='/Posts' className={styles.link}>
            {t('navPosts')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link to='/Contact' className={styles.link}>
            {t('navContactUs')}
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Links
