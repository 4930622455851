import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface WhoWeAreProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useWhoWeAre = (props: WhoWeAreProps) => {
  const whoWeAreData = useSelector((state: RootState) => state.about.whoWeAre)

  return {
    ...props,
    whoWeAreData,
  }
}
