import React from 'react'
import { Link } from 'react-router-dom'
import { useHeader, HeaderProps } from './useHeader'
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'fontawesome'
import styles from './Header.module.scss'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { scrolled, isMobileNavActive, toggleMobileNav, t } = useHeader(props)

  return (
    <header
      id='header'
      className={`${styles.header} ${isMobileNavActive ? styles['mobile-nav-active'] : ''} d-flex align-items-center fixed-top`}
    >
      <div
        className={`${styles['header-container']} ${scrolled ? styles.scrolled : ''} container-fluid container-xl position-relative d-flex align-items-center justify-content-between`}
      >
        <Link
          to='/'
          className={`${styles.logo} d-flex align-items-center me-auto me-xl-0`}
        >
          <img
            src='/img/Logos/logo-black.png'
            alt='logo'
            height={45}
            className='d-none d-xl-block'
          />
          <img
            src='/img/Logos/logo-squared.png'
            alt='logo'
            height={45}
            className='d-xl-none'
          />
        </Link>
        <nav id='navmenu' className={`${styles.navmenu}`}>
          <ul>
            <li>
              <Link to='/' onClick={() => toggleMobileNav(false)}>
                {t('navHome')}
              </Link>
            </li>
            <li>
              <Link to='/About' onClick={() => toggleMobileNav(false)}>
                {t('navAboutUs')}
              </Link>
            </li>
            <li>
              <Link to='/OurBranches' onClick={() => toggleMobileNav(false)}>
                {t('navOurBranches')}
              </Link>
            </li>
            <li>
              <Link to='/OurTeam' onClick={() => toggleMobileNav(false)}>
                {t('navOurTeam')}
              </Link>
            </li>
            <li>
              <Link to='/Posts' onClick={() => toggleMobileNav(false)}>
                {t('navPosts')}
              </Link>
            </li>
            <li>
              <Link to='/Contact' onClick={() => toggleMobileNav(false)}>
                {t('navContactUs')}
              </Link>
            </li>
          </ul>
          <span onClick={() => toggleMobileNav()}>
            <FontAwesomeIcon
              icon={isMobileNavActive ? 'x' : 'bars'}
              size='xl'
              className={`${styles['mobile-nav-toggle']} d-xl-none`}
            />
          </span>
        </nav>
        <LanguageSwitcher />
      </div>
    </header>
  )
}

export default Header
