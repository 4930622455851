export interface GoalCardProps {
  title: string
  description: any
  icon: string
  iconPrefix: string
}

export const useGoalCard = (props: GoalCardProps) => {
  return {
    ...props,
  }
}
