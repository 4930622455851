export interface WhoWeAreCardProps {
  title: string
  description: any
  icon: string
  iconPrefix: string
}

export const useWhoWeAreCard = (props: WhoWeAreCardProps) => {
  return {
    ...props,
  }
}
