import { createSlice } from '@reduxjs/toolkit'
import { IPosts } from 'models/Posts/IPosts'
import { IPostCategory } from 'models/Posts/IPostCategory'
import { IPostDetails } from 'models/Posts/IPostDetails'
import ITag from 'models/Common/ITag'

export interface PostsState {
  isLoading: boolean
  isLoadingPaginatedPosts: boolean
  isGetLatestPostsSuccess: boolean
  isGetFeaturedPostsSuccess: boolean
  isGetPostsCountSuccess: boolean
  isGetPostCategoriesSuccess: boolean
  isGetPostTagsSuccess: boolean
  isGetPostDetailsSuccess: boolean
  latestPostsData: IPosts[] | null
  featuredPostsData: IPosts[] | null
  paginatedPostsData: IPosts[] | null
  postCategories: IPostCategory[] | null
  postTags: ITag[] | null
  postDetails: IPostDetails[] | null
  postsCount: number
}

const initialState: PostsState = {
  isLoading: false,
  isLoadingPaginatedPosts: false,
  isGetLatestPostsSuccess: false,
  isGetFeaturedPostsSuccess: false,
  isGetPostsCountSuccess: false,
  isGetPostCategoriesSuccess: false,
  isGetPostTagsSuccess: false,
  isGetPostDetailsSuccess: false,
  latestPostsData: null,
  featuredPostsData: null,
  paginatedPostsData: null,
  postCategories: null,
  postTags: null,
  postDetails: null,
  postsCount: 0,
}

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
      state.isLoadingPaginatedPosts = false
    },
    paginatedPostsRequest: (state) => {
      state.isLoadingPaginatedPosts = true
    },
    clearPostDetails: (state) => {
      state.isGetPostDetailsSuccess = false
      state.postDetails = null
    },
    getLatestPosts(state, action) {
      state.isLoading = false
      state.isGetLatestPostsSuccess = true
      state.latestPostsData = action.payload.latestPostsData.result
    },
    getFeaturedPosts(state, action) {
      state.isLoading = false
      state.isGetFeaturedPostsSuccess = true
      state.featuredPostsData = action.payload.featuredPostsData.result
    },
    getPostsCount(state, action) {
      state.isLoading = false
      state.isGetPostsCountSuccess = true
      state.postsCount = action.payload.postsCount.result.length
    },
    getPostTags(state, action) {
      state.isLoading = false
      state.isGetPostCategoriesSuccess = true
      state.postTags = action.payload.postTags.result
    },
    getPostCategories(state, action) {
      state.isLoading = false
      state.isGetPostCategoriesSuccess = true
      state.postCategories = action.payload.postCategories.result
    },
    getPostDetails(state, action) {
      state.isLoading = false
      state.isGetPostDetailsSuccess = true
      state.postDetails = action.payload.postDetails.result
    },
    getPaginatedPostsSearch(state, action) {
      state.isLoadingPaginatedPosts = false
      state.paginatedPostsData = action.payload.paginatedPosts.result
    },
    getPaginatedPostsLoadMore(state, action) {
      state.isLoadingPaginatedPosts = false

      const existingIds = new Set(
        state.paginatedPostsData?.map((post) => post.id) || []
      )
      const newUniquePosts = action.payload.paginatedPosts.result.filter(
        (post: any) => !existingIds.has(post.id)
      )

      state.paginatedPostsData = [
        ...(state.paginatedPostsData || []),
        ...newUniquePosts,
      ]
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  paginatedPostsRequest,
  clearPostDetails,
  getLatestPosts,
  getFeaturedPosts,
  getPostsCount,
  getPaginatedPostsSearch,
  getPaginatedPostsLoadMore,
  getPostCategories,
  getPostTags,
  getPostDetails,
} = postsSlice.actions

export default postsSlice.reducer
