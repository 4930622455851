import { TranslationKeys } from 'types/translationKeys'
import { IPosts } from 'models/Posts/IPosts'

export interface ListProps {
  posts: IPosts[] | null
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useList = (props: ListProps) => {
  return {
    ...props,
  }
}
