import React from 'react'
import { useLoadMore, LoadMoreProps } from './useLoadMore'

const LoadMore: React.FC<LoadMoreProps> = (props: LoadMoreProps) => {
  const { posts, postsCount, isLoading, t, handleLoadMore } = useLoadMore(props)

  return (
    <div className='row mt-4 mb-5'>
      <div className='col-12 text-center'>
        <button
          type='button'
          className='btn btn-primary px-5 py-2'
          style={{
            display:
              posts && posts.length && postsCount > posts.length
                ? 'initial'
                : 'none',
          }}
          disabled={isLoading}
          onClick={() => handleLoadMore()}
        >
          {isLoading ? `${t('loading')}...` : t('loadMore')}
        </button>
      </div>
    </div>
  )
}

export default LoadMore
