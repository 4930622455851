import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getBranchDetails, clearBranchDetails } from 'actions/branches.action'
import { RootState } from 'reducers/rootReducer'

export interface BranchDetailsProps {}

export const useBranchDetails = (props: BranchDetailsProps) => {
  const dispatch = useAppDispatch()

  const { slug } = useParams<{ slug: string }>()

  const branchDetails = useSelector(
    (state: RootState) => state.branches.branchDetails
  )
  const isLoading = useSelector((state: RootState) => state.branches.isLoading)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!branchDetails && slug) dispatch(getBranchDetails(slug))
  }, [branchDetails, slug])

  useEffect(() => {
    return () => {
      dispatch(clearBranchDetails())
    }
  }, [dispatch, slug])

  return {
    ...props,
    isLoading,
    branchDetails,
  }
}
