import React from 'react'
import { useDetails, DetailsProps } from './useDetails'
import blockContentToHtml from '@sanity/block-content-to-html'

const Details: React.FC<DetailsProps> = (props: DetailsProps) => {
  const { details, isAr, styles } = useDetails(props)

  return (
    <div className='col-lg-8 col-xl-9'>
      <img src={details[0].image} className={styles.image} />
      <div className={styles.content}>
        <h1 className={styles.title}>
          {isAr ? details[0].titleAr : details[0].titleEn}
        </h1>
        <div className={styles.date}>
          <i className='bi bi-clock me-2'></i>
          <time dir='ltr'>
            {`${details[0].publishedAt.split('T')[0]} ${details[0].publishedAt.split('T')[1].slice(0, -8)}`}
          </time>
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: isAr
              ? Array.isArray(details[0].descriptionAr)
                ? blockContentToHtml({
                    blocks: details[0].descriptionAr,
                  })
                : `<p>${details[0].descriptionAr}</p>`
              : Array.isArray(details[0].descriptionEn)
                ? blockContentToHtml({
                    blocks: details[0].descriptionEn,
                  })
                : `<p>${details[0].descriptionEn}</p>`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default Details
