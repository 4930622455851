import React from 'react'
import {
  useFilterController,
  FilterControllerProps,
} from './useFilterController'

const FilterController: React.FC<FilterControllerProps> = (
  props: FilterControllerProps
) => {
  const {
    searchTerm,
    selectedCategory,
    selectedTag,
    postCategories,
    postTags,
    isLoading,
    isAr,
    t,
    setSearchTerm,
    setSelectedCategory,
    setSelectedTag,
    handleSearch,
    handleKeyDown,
  } = useFilterController(props)

  return (
    <div className='row mb-4'>
      <div className='col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
        <input
          type='search'
          className='form-control'
          placeholder={`${t('title')}...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
        <select
          className='form-select'
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value='0'>{t('category')}</option>
          {postCategories &&
            postCategories.length &&
            postCategories.map((category, index) => (
              <option
                key={`category-${category.id}-${index}`}
                value={category.id}
              >
                {isAr ? category.nameAr : category.nameEn}
              </option>
            ))}
        </select>
      </div>
      <div className='col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
        <select
          className='form-select'
          value={selectedTag}
          onChange={(e) => setSelectedTag(e.target.value)}
        >
          <option value='0'>{t('tag')}</option>
          {postTags &&
            postTags.length &&
            postTags.map((tag, index) => (
              <option key={`tag-${tag.id}-${index}`} value={tag.id}>
                {isAr ? tag.tagAr : tag.tagEn}
              </option>
            ))}
        </select>
      </div>
      <div className='col-12 col-md-6 col-lg-3'>
        <button
          type='button'
          className='btn btn-primary fw-bold w-100'
          disabled={isLoading}
          onClick={() => handleSearch()}
        >
          <i className='bi bi-search me-2'></i>
          {isLoading ? `${t('pleaseWait')}...` : t('search')}
        </button>
      </div>
    </div>
  )
}

export default FilterController
