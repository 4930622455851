import React from 'react'
import {
  useBranchDescription,
  BranchDescriptionProps,
} from './useBranchDescription'
import blockContentToHtml from '@sanity/block-content-to-html'

const BranchDescription: React.FC<BranchDescriptionProps> = (
  props: BranchDescriptionProps
) => {
  const { description } = useBranchDescription(props)

  return (
    <section className='pb-5'>
      <div className='container'>
        <div className='row'>
          <div
            className='col-12'
            dangerouslySetInnerHTML={{
              __html: Array.isArray(description)
                ? blockContentToHtml({
                    blocks: description,
                  })
                : `<p>${description}</p>`,
            }}
          ></div>
        </div>
      </div>
    </section>
  )
}

export default BranchDescription
