import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getSlider } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'

export interface HeroProps {
  isAr: boolean
}

export const useHero = (props: HeroProps) => {
  const dispatch = useAppDispatch()

  const sliderData = useSelector((state: RootState) => state.home.sliderData)

  useEffect(() => {
    if (!sliderData) dispatch(getSlider())
  }, [sliderData])

  return {
    ...props,
    sliderData,
  }
}
