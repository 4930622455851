import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { TranslationKeys } from 'types/translationKeys'

type Namespace = 'translation'

export const useCustomTranslation = () => {
  const { t, i18n }: UseTranslationResponse<Namespace, TranslationKeys> =
    useTranslation<Namespace, TranslationKeys>()

  const customT = (key: TranslationKeys) => t(key)

  return { t: customT, i18n }
}
