export interface LatestPostProps {
  image: string
  title: string
  link: string
  date: string
  isWhite: boolean
}

export const useLatestPost = (props: LatestPostProps) => {
  return {
    ...props,
  }
}
