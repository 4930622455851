import React from 'react'
import { useServices, ServicesProps } from './useServices'
import styles from './Services.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import ServiceCard from 'components/ServiceCard/ServiceCard'

const Services: React.FC<ServicesProps> = (props: ServicesProps) => {
  const { servicesData, isAr, t } = useServices(props)

  return (
    <section id='services' className={styles.services}>
      <TitleSection title={t('ourServices')} />
      <div className='container'>
        <div className='row g-4'>
          {servicesData &&
            servicesData.length &&
            servicesData.map((item, index) => (
              <div key={`service-${item.id}-${index}`} className='col-lg-6'>
                <ServiceCard
                  title={isAr ? item.titleAr : item.titleEn}
                  description={isAr ? item.descriptionAr : item.descriptionEn}
                  icon={item.icon}
                  iconPrefix={item.iconPrefix}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Services
