import React from 'react'
import { useGoals, GoalsProps } from './useGoals'
import styles from './Goals.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import GoalCard from 'components/GoalCard/GoalCard'

const Goals: React.FC<GoalsProps> = (props: GoalsProps) => {
  const { goalsData, isAr, t } = useGoals(props)

  return (
    <section id='goals' className={styles.goals}>
      <TitleSection title={t('ourGoals')} />
      <div className='container'>
        <div className='row g-4'>
          {goalsData &&
            goalsData.length &&
            goalsData.map((item, index) => (
              <div
                key={`goal-${item.id}-${index}`}
                className='col-lg-6 col-xl-4'
              >
                <GoalCard
                  title={isAr ? item.titleAr : item.titleEn}
                  description={isAr ? item.descriptionAr : item.descriptionEn}
                  icon={item.icon}
                  iconPrefix={item.iconPrefix}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Goals
