import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getLatestPosts } from 'actions/posts.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface LatestPostsProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useLatestPosts = (props: LatestPostsProps) => {
  const dispatch = useAppDispatch()

  const latestPostsData = useSelector(
    (state: RootState) => state.posts.latestPostsData
  )

  useEffect(() => {
    if (!latestPostsData) dispatch(getLatestPosts())
  }, [latestPostsData])

  return {
    ...props,
    latestPostsData,
  }
}
