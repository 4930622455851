import React from 'react'
import { useCopyright, CopyrightProps } from './useCopyright'
import styles from './Copyright.module.scss'

const Copyright: React.FC<CopyrightProps> = (props: CopyrightProps) => {
  const { thisYear, t } = useCopyright(props)

  return (
    <div className={`container ${styles.copyright} text-center mt-4`}>
      <p className='mb-0'>
        {t('copyright')} {thisYear}
        <strong className='px-1 sitename'>{t('websiteName')}</strong>
        <span>{t('allRightsReserved')}</span>
      </p>
      <div>
        {t('poweredBy')}{' '}
        <a
          href='https://www.linkedin.com/company/candledigitals/'
          target='_blank'
          rel='noreferrer'
          className='text-white fw-bold text-decoration-none text-hover-secondary transition-0-4'
        >
          Candle Digital Solutions
        </a>
      </div>
    </div>
  )
}

export default Copyright
