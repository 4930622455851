import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface GoalsProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useGoals = (props: GoalsProps) => {
  const goalsData = useSelector((state: RootState) => state.about.goals)

  return {
    ...props,
    goalsData,
  }
}
