import React from 'react'
import { usePostsListing, PostsListingProps } from './usePostsListing'
import FilterController from './FilterController/FilterController'
import List from './List/List'
import LoadMore from './LoadMore/LoadMore'

const PostsListing: React.FC<PostsListingProps> = (
  props: PostsListingProps
) => {
  const {
    searchTerm,
    selectedCategory,
    selectedTag,
    paginatedPostsData,
    postsCount,
    postCategories,
    postTags,
    isLoading,
    isAr,
    t,
    setSearchTerm,
    setSelectedCategory,
    setSelectedTag,
    handleLoadMore,
    handleSearch,
    handleKeyDown,
  } = usePostsListing(props)

  return (
    <section>
      <div className='container pt-5'>
        <FilterController
          searchTerm={searchTerm}
          selectedCategory={selectedCategory}
          selectedTag={selectedTag}
          postCategories={postCategories}
          postTags={postTags}
          isLoading={isLoading}
          isAr={isAr}
          t={t}
          setSearchTerm={setSearchTerm}
          setSelectedCategory={setSelectedCategory}
          setSelectedTag={setSelectedTag}
          handleSearch={handleSearch}
          handleKeyDown={handleKeyDown}
        />
        <List posts={paginatedPostsData} isAr={isAr} t={t} />
        <LoadMore
          posts={paginatedPostsData}
          postsCount={postsCount}
          isLoading={isLoading}
          t={t}
          handleLoadMore={handleLoadMore}
        />
      </div>
    </section>
  )
}

export default PostsListing
