import React, { Fragment } from 'react'
import { useWhoWeAre, WhoWeAreProps } from './useWhoWeAre'
import styles from './WhoWeAre.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import WhoWeAreCard from 'components/WhoWeAreCard/WhoWeAreCard'

const WhoWeAre: React.FC<WhoWeAreProps> = (props: WhoWeAreProps) => {
  const { whoWeAreData, isAr, t } = useWhoWeAre(props)

  return (
    <section id='who-we-are' className={styles['who-we-are']}>
      <TitleSection title={t('knowUsMore')} />
      {whoWeAreData && whoWeAreData.length ? (
        <div className='container'>
          <div className='row'>
            {whoWeAreData.map((item, index) => (
              <div
                key={`whoweare-${item.id}-${index}`}
                className='col-md-6 col-xl-3 mb-5 mb-xl-0'
              >
                <WhoWeAreCard
                  title={isAr ? item.titleAr : item.titleEn}
                  description={isAr ? item.descriptionAr : item.descriptionEn}
                  icon={item.icon}
                  iconPrefix={item.iconPrefix}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </section>
  )
}

export default WhoWeAre
