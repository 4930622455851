export const ENDPOINTS = {
  All: {
    GetAll: 'query/production?query=*',
  },
  Home: {
    GetSlider:
      'query/production?query=*[_type == "homeSlider"]{ "id": _id, "titleAr": title.ar, "titleEn": title.en,  "descriptionAr": description.ar, "descriptionEn": description.en, "button": button->{ "labelAr": label.ar, "labelEn": label.en, "url": url, "style": style, icon },  "image": BG.asset->url }',
    GetPartners:
      'query/production?query=*[_type == "partner"]{ "id": _id, "nameAr": name.ar, "nameEn": name.en, "logo": logo.asset->url, "url": url }',
    GetSocialMedia:
      'query/production?query=*[_type == "socialLink"]{ "id": _id, platform, url, icon, iconPrefix }',
  },
  About: {
    GetAbout:
      'query/production?query=*[_type == "organisationInfo"]{ "id": _id, "titleAr": title.ar, "titleEn": title.en, "subTitleAr": subTitle.ar, "subTitleEn": subTitle.en, "descriptionAr": description.ar, "descriptionEn": description.en, "image": image.asset->url, "infoSection": infoSection->name.en, icon, iconPrefix }',
  },
  Posts: {
    GetPostsCountPrefix: 'query/production?query=*[_type == "post"]',
    GetPostsCountSuffix: '{ "id": _id }',
    GetLatestPosts:
      'query/production?query=*[_type == "post"] | order(publishedAt desc)[0..2]{ "id": _id, "updatedAt": _updatedAt, "titleAr": title.ar, "titleEn": title.en, "slugAr": slug.ar.current, "slugEn": slug.en.current, "image": image.asset->url, "descriptionAr": description.ar, "descriptionEn": description.en, author->{ "nameAr": name.ar, "nameEn": name.en, "photo": photo.asset->url }, category->{ "nameAr": name.ar, "nameEn": name.en }, featured, publishedAt }',
    GetFeaturedPosts:
      'query/production?query=*[_type == "post"][featured == true] | order(publishedAt desc)[0..2]{ "id": _id, "updatedAt": _updatedAt, "titleAr": title.ar, "titleEn": title.en, "slugAr": slug.ar.current, "slugEn": slug.en.current, "image": image.asset->url, author->{ "nameAr": name.ar, "nameEn": name.en, "photo": photo.asset->url }, "descriptionAr": description.ar, "descriptionEn": description.en, category->{ "nameAr": name.ar, "nameEn": name.en }, featured, publishedAt }',
    GetPaginatedPosts: 'query/production',
    GetPostDetailsPrefix:
      'query/production?query=*[_type == "post"][slug.ar.current == "',
    GetPostDetailsSuffix:
      '"]{ "id": _id, "updatedAt": _updatedAt, "titleAr": title.ar, "titleEn": title.en, "slugAr": slug.ar.current, "slugEn": slug.en.current,  author->{ "id": _id, "nameAr": name.ar, "nameEn": name.en, "url": URL, "bioAr": authorBio.ar, "bioEn": authorBio.en, "photo": photo.asset->url }, category->{ "id": _id, "nameAr": name.ar, "nameEn": name.en }, "image": image.asset->url, "descriptionAr": description.ar, "descriptionEn": description.en, tags[]->{ "id": _id, "tagAr": label.ar, "tagEn": label.en }, "featured": featured, "publishedAt": publishedAt }',
    GetPostCategories:
      'query/production?query=*[_type == "postCategory"]{ "id": _id, "nameAr": name.ar, "nameEn": name.en }',
    GetPostTags:
      'query/production?query=*[_type == "tag"]{ "id": _id, "tagAr": label.ar, "tagEn": label.en }',
  },
  Team: {
    GetOurTeam:
      'query/production?query=*[_type == "profile"] | order(order asc){ "nameAr": name.ar, "nameEn": name.en, "photo": photo.asset->url, "bioAr": bio.ar, "bioEn": bio.en, "roleAr": role.ar, "roleEn": role.en, "socialLinks": socialLinks[]{ "id": _id, platform, "url": URL, icon, iconPrefix }, order}',
  },
  Contact: {
    GetContactSocials:
      'query/production?query=*[_type == "contact"]{ "id": _id, "nameAr": name.ar, "nameEn": name.en, "displayedAr": displayed.ar, "displayedEn": displayed.en, iconPrefix, icon }',
    Post: 'mutate/production',
  },
  Branches: {
    GetOurBranches:
      'query/production?query=*[_type == "branch"]{ "id": _id, "titleAr": title.ar, "titleEn": title.en, "subTitleAr": subTitle.ar, "subTitleEn": subTitle.en, "slugAr": slug.ar.current, "slugEn": slug.en.current, "featuredImage": featuredImage.asset->url, "descriptionAr": description.ar, "descriptionEn": description.en, yearOfFoundation, numberOfVolunteers,  "annualActivitiesAr": annualActivities.ar, "annualActivitiesEn": annualActivities.en, "imagesGallery": imagesGallery[].asset->url, location}',
    GetBranchDetailsPrefix:
      'query/production?query=*[_type == "branch"][slug.ar.current == "',
    GetBranchDetailsSuffix:
      '"]{ "id": _id, "titleAr": title.ar, "titleEn": title.en, "subTitleAr": subTitle.ar, "subTitleEn": subTitle.en, "slugAr": slug.ar.current, "slugEn": slug.en.current, "featuredImage": featuredImage.asset->url, "descriptionAr": description.ar, "descriptionEn": description.en, yearOfFoundation, numberOfVolunteers,  "annualActivitiesAr": annualActivities.ar, "annualActivitiesEn": annualActivities.en, "imagesGallery": imagesGallery[].asset->url, location}',
  },
}
