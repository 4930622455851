import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getOurTeam as getOurTeamReducer,
} from 'reducers/team.reducer'
import { teamService } from 'services/team.service'

export const getOurTeam = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await teamService.getOurTeam()
    if (response) {
      const ourTeam = response

      dispatch(getOurTeamReducer({ ourTeam }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}
