import React from 'react'
import { useFeaturedPosts, FeaturedPostsProps } from './useFeaturedPosts'
import styles from './FeaturedPosts.module.scss'
import { Link } from 'react-router-dom'
import TitleSection from 'components/TitleSection/TitleSection'
import PostCard from 'components/PostCard/PostCard'

const FeaturedPosts: React.FC<FeaturedPostsProps> = (
  props: FeaturedPostsProps
) => {
  const { featuredPostsData, isAr, t } = useFeaturedPosts(props)

  return (
    <section id='featured-posts' className={styles['featured-posts']}>
      <TitleSection title={t('FeaturedPosts')} />
      <div className='container'>
        <div className='row mb-4 mb-lg-5'>
          {featuredPostsData &&
            featuredPostsData.length &&
            featuredPostsData.map((post, index) => {
              const description = isAr ? post.descriptionAr : post.descriptionEn
              let shortDescription = ''

              if (
                description.length > 0 &&
                description[0].children &&
                description[0].children.length > 0
              ) {
                const firstText = description[0].children[0].text
                shortDescription = `${firstText.substring(0, 100)}...`
              } else shortDescription = t('noDetails')

              return (
                <div
                  key={`post-${post.id}-${index}`}
                  className='col-lg-4 mb-3 mb-lg-0'
                >
                  <PostCard
                    image={post.image}
                    title={isAr ? post.titleAr : post.titleEn}
                    description={shortDescription}
                    author={isAr ? post.author.nameAr : post.author.nameEn}
                    authorImage={post.author.photo}
                    category={
                      isAr ? post.category.nameAr : post.category.nameEn
                    }
                    url={`/PostDetails/${post.slugAr}`}
                    date={post.publishedAt.split('T')[0]}
                    isFeatured={!!post.featured}
                    isAr={isAr}
                  />
                </div>
              )
            })}
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <Link to='/Posts' className='btn btn-secondary px-5 py-2'>
              {t('AllPosts')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedPosts
