import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getContactSocials as getContactSocialsReducer,
  sendContactMessage as sendContactMessageReducer,
} from 'reducers/contact.reducer'
import { contactService } from 'services/contact.service'
import { IContactMutation } from 'models/Contact/IContact'

export const getContactSocials = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await contactService.getContactSocials()
    if (response) {
      const contactSocials = response

      dispatch(getContactSocialsReducer({ contactSocials }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const sendContactMessage =
  (contactInfo: IContactMutation) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await contactService.sendContactMessage(contactInfo)
      if (response) {
        const contactMessageResponse = response
        dispatch(sendContactMessageReducer({ contactMessageResponse }))
      }
    } catch (error) {
      console.error(error)
      dispatch(apiFailure())
    }
  }
