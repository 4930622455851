import React, { memo } from 'react'
import { useWhoWeAreCard, WhoWeAreCardProps } from './useWhoWeAreCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './WhoWeAreCard.module.scss'

const WhoWeAreCard: React.FC<WhoWeAreCardProps> = (
  props: WhoWeAreCardProps
) => {
  const { title, description, icon, iconPrefix } = useWhoWeAreCard(props)

  return (
    <div className={styles['who-we-are-card']}>
      <div className={styles['icon-container']}>
        <FontAwesomeIcon
          icon={[
            (iconPrefix as IconPrefix) || 'fas',
            (icon as IconName) || 'question',
          ]}
          className={styles.icon}
        />
      </div>
      <h3>{title}</h3>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: Array.isArray(description)
            ? blockContentToHtml({
                blocks: description,
              })
            : `<p>${description}</p>`,
        }}
      ></div>
    </div>
  )
}

export default memo(WhoWeAreCard)
