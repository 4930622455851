import React from 'react'
import { useLatestPosts, LatestPostsProps } from './useLatestPosts'
import styles from './LatestPosts.module.scss'
import LatestPost from 'components/LatestPost/LatestPost'

const LatestPosts: React.FC<LatestPostsProps> = (props: LatestPostsProps) => {
  const { latestPosts, isAr, t } = useLatestPosts(props)

  return (
    <div className={`${styles['latest-posts']} col-xl-4`}>
      <h4 className={styles.title}>{t('latestPosts')}</h4>
      {latestPosts && latestPosts.length ? (
        latestPosts.map((post, index) => (
          <LatestPost
            key={`post-${post.id}-${index}`}
            image={post.image}
            title={isAr ? post.titleAr : post.titleEn}
            link={`/PostDetails/${post.slugAr}`}
            date={post.publishedAt.split('T')[0]}
            isWhite
          />
        ))
      ) : (
        <div className='col-12 fs-4 fw-bold py-5'>{t('noData')}</div>
      )}
    </div>
  )
}

export default LatestPosts
