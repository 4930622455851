import React from 'react'
import { useValues, ValuesProps } from './useValues'
import styles from './Values.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import ValueCard from 'components/ValueCard/ValueCard'

const Values: React.FC<ValuesProps> = (props: ValuesProps) => {
  const { valuesData, isAr, t } = useValues(props)

  return (
    <section id='values' className={styles.values}>
      <TitleSection title={t('ourValues')} />
      <div className='container'>
        <div className={`${styles['values-container']} row g-4 mx-1 mx-md-0`}>
          {valuesData &&
            valuesData.length &&
            valuesData.map((item, index) => (
              <div
                key={`value-${item.id}-${index}`}
                className='col-md-4 col-lg-3'
              >
                <ValueCard
                  title={isAr ? item.titleAr : item.titleEn}
                  description={isAr ? item.descriptionAr : item.descriptionEn}
                  icon={item.icon}
                  iconPrefix={item.iconPrefix}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Values
