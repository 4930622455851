import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getPartners } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface PartnersProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const usePartners = (props: PartnersProps) => {
  const dispatch = useAppDispatch()

  const partnersData = useSelector(
    (state: RootState) => state.home.partnersData
  )

  useEffect(() => {
    if (!partnersData) dispatch(getPartners())
  }, [partnersData])

  return {
    ...props,
    partnersData,
  }
}
