export interface BranchTitleProps {
  title: string
  subTitle: string
}

export const useBranchTitle = (props: BranchTitleProps) => {
  return {
    ...props,
  }
}
