import React, { memo } from 'react'
import { usePostCard, PostCardProps } from './usePostCard'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'fontawesome'
import styles from './PostCard.module.scss'

const PostCard: React.FC<PostCardProps> = (props: PostCardProps) => {
  const {
    title,
    description,
    author,
    category,
    image,
    authorImage,
    url,
    day,
    monthAndYear,
    isFeatured,
    isAr,
    t,
  } = usePostCard(props)

  return (
    <Link to={url} style={{ textDecoration: 'none' }}>
      <div
        className={`${styles['post-card']} ${isFeatured ? styles.featured : ''} position-relative h-100`}
      >
        <div
          className={`${styles['post-img']} position-relative overflow-hidden`}
        >
          <img src={image} alt='post' height={260} width='100%' />
          <span className={`${styles['post-date']}`}>
            {day}&nbsp;{monthAndYear}
          </span>
        </div>
        <div className={styles.meta}>
          <div className='d-flex align-items-center'>
            {authorImage ? (
              <img
                src={authorImage}
                width={35}
                className='rounded-circle flex-shrink-0'
                alt='author image'
              />
            ) : (
              <FontAwesomeIcon
                icon='pen-nib'
                color={isFeatured ? '#e8dfca' : '#035373'}
              />
            )}
            <span className='ps-2'>{author}</span>
          </div>
          <span className='px-3 text-black-50'>/</span>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon='folder'
              color={isFeatured ? '#e8dfca' : '#035373'}
              size='lg'
            />{' '}
            <span className='ps-2'>{category}</span>
          </div>
        </div>
        <div className={`${styles['post-content']} d-flex flex-column`}>
          <h4 className={`${styles['post-title']}`}>{title}</h4>
          <p className={styles['post-description']}>{description}</p>
          <div className={styles.link}>
            <span>{t('loadMore')}</span>
            <FontAwesomeIcon
              icon={isAr ? 'arrow-left' : 'arrow-right'}
              className='ms-2'
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(PostCard)
