import { Dispatch, SetStateAction } from 'react'
import ITag from 'models/Common/ITag'
import { IPostCategory } from 'models/Posts/IPostCategory'
import { TranslationKeys } from 'types/translationKeys'

export interface FilterControllerProps {
  searchTerm: string
  selectedCategory: string
  selectedTag: string
  postCategories: IPostCategory[] | null
  postTags: ITag[] | null
  isLoading: boolean
  isAr: boolean
  t: (key: TranslationKeys) => string
  setSearchTerm: Dispatch<SetStateAction<string>>
  setSelectedCategory: Dispatch<SetStateAction<string>>
  setSelectedTag: Dispatch<SetStateAction<string>>
  handleSearch: () => void
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const useFilterController = (props: FilterControllerProps) => {
  return {
    ...props,
  }
}
