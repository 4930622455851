import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getOurTeam } from 'actions/team.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface TeamListingProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useTeamListing = (props: TeamListingProps) => {
  const dispatch = useAppDispatch()

  const ourTeam = useSelector((state: RootState) => state.team.ourTeam)

  useEffect(() => {
    if (!ourTeam) dispatch(getOurTeam())
  }, [ourTeam])

  return {
    ...props,
    ourTeam,
  }
}
