import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getPaginatedPosts,
  getPostsCount,
  getPostCategories,
  getPostTags,
} from 'actions/posts.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface PostsListingProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const usePostsListing = (props: PostsListingProps) => {
  const { isAr } = props

  const dispatch = useAppDispatch()

  enum InitialValues {
    From = 0,
    To = 5,
  }

  const [from, setFrom] = useState(InitialValues.From)
  const [to, setTo] = useState(InitialValues.To)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedTag, setSelectedTag] = useState('')

  const paginatedPostsData = useSelector(
    (state: RootState) => state.posts.paginatedPostsData
  )
  const postCategories = useSelector(
    (state: RootState) => state.posts.postCategories
  )
  const postTags = useSelector((state: RootState) => state.posts.postTags)
  const isLoading = useSelector(
    (state: RootState) => state.posts.isLoadingPaginatedPosts
  )
  const postsCount = useSelector((state: RootState) => state.posts.postsCount)

  const handleLoadMore = () => {
    setFrom(from + 6)
    setTo(to + 6)
  }

  const handleSearch = () => {
    setFrom(InitialValues.From)
    setTo(InitialValues.To)
    dispatch(
      getPaginatedPosts(
        InitialValues.From,
        InitialValues.To,
        searchTerm,
        selectedCategory,
        selectedTag,
        isAr
      )
    )
    dispatch(getPostsCount(searchTerm, selectedCategory, selectedTag, isAr))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (!paginatedPostsData) {
      dispatch(
        getPaginatedPosts(
          InitialValues.From,
          InitialValues.To,
          searchTerm,
          selectedCategory,
          selectedTag,
          isAr
        )
      )
      dispatch(getPostsCount(searchTerm, selectedCategory, selectedTag, isAr))
      dispatch(getPostCategories())
      dispatch(getPostTags())
    }
  }, [paginatedPostsData])

  useEffect(() => {
    if (from !== InitialValues.From) {
      dispatch(
        getPaginatedPosts(
          from,
          to,
          searchTerm,
          selectedCategory,
          selectedTag,
          isAr
        )
      )
      dispatch(getPostsCount(searchTerm, selectedCategory, selectedTag, isAr))
    }
  }, [from, to])

  return {
    ...props,
    searchTerm,
    selectedCategory,
    selectedTag,
    paginatedPostsData,
    postsCount,
    postCategories,
    postTags,
    isLoading,
    setSearchTerm,
    setSelectedCategory,
    setSelectedTag,
    handleLoadMore,
    handleSearch,
    handleKeyDown,
  }
}
