import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getAbout } from 'actions/about.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface AboutProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useAbout = (props: AboutProps) => {
  const dispatch = useAppDispatch()

  const aboutData = useSelector((state: RootState) => state.about.whoWeAre)

  useEffect(() => {
    if (!aboutData) dispatch(getAbout())
  }, [aboutData])

  return {
    ...props,
    aboutData,
  }
}
