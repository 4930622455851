import React from 'react'
import { useList, ListProps } from './useList'
import PostCard from 'components/PostCard/PostCard'

const List: React.FC<ListProps> = (props: ListProps) => {
  const { posts, isAr, t } = useList(props)

  return (
    <div className='row'>
      {posts && posts.length ? (
        posts.map((post, index) => {
          const description = isAr ? post.descriptionAr : post.descriptionEn
          let shortDescription = ''

          if (
            description.length > 0 &&
            description[0].children &&
            description[0].children.length > 0
          ) {
            const firstText = description[0].children[0].text
            shortDescription = `${firstText.substring(0, 100)}...`
          } else shortDescription = t('noDetails')

          return (
            <div
              key={`post-${post.id}-${index}`}
              className='col-12 col-md-6 col-lg-4 mb-3'
            >
              <PostCard
                image={post.image}
                title={isAr ? post.titleAr : post.titleEn}
                description={shortDescription}
                author={isAr ? post.author.nameAr : post.author.nameEn}
                authorImage={post.author.photo}
                category={isAr ? post.category.nameAr : post.category.nameEn}
                url={`/PostDetails/${post.slugAr}`}
                date={post.publishedAt.split('T')[0]}
                isFeatured={!!post.featured}
                isAr={isAr}
              />
            </div>
          )
        })
      ) : (
        <div className='col-12 text-center fs-4 fw-bold'>{t('noData')}</div>
      )}
    </div>
  )
}

export default List
