import { TranslationKeys } from 'types/translationKeys'

export interface CopyrightProps {
  t: (key: TranslationKeys) => string
}

export const useCopyright = (props: CopyrightProps) => {
  const thisYear = new Date().getFullYear()

  return {
    ...props,
    thisYear,
  }
}
