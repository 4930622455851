import { useState, useEffect } from 'react'
import { useCustomTranslation } from 'hooks/useCustomTranslation'

export interface HeaderProps {}

export const useHeader = (props: HeaderProps) => {
  const [scrolled, setScrolled] = useState(false)
  const [isMobileNavActive, setIsMobileNavActive] = useState(false)

  const { t } = useCustomTranslation()

  const toggleMobileNav = (toggle?: boolean) => {
    if (typeof toggle === 'boolean') setIsMobileNavActive(toggle)
    else setIsMobileNavActive(!isMobileNavActive)
  }

  useEffect(() => {
    if (window.scrollY > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return {
    ...props,
    scrolled,
    isMobileNavActive,
    toggleMobileNav,
    t,
  }
}
