interface Month {
  ar: string
  en: string
}

const monthNames: Month[] = [
  {
    ar: 'كانون الثاني',
    en: 'January',
  },
  {
    ar: 'شباط',
    en: 'February',
  },
  {
    ar: 'آذار',
    en: 'March',
  },
  {
    ar: 'نيسان',
    en: 'April',
  },
  {
    ar: 'أيار',
    en: 'May',
  },
  {
    ar: 'حزيران',
    en: 'June',
  },
  {
    ar: 'تموز',
    en: 'July',
  },
  {
    ar: 'آب',
    en: 'August',
  },
  {
    ar: 'أيلول',
    en: 'September',
  },
  {
    ar: 'تشرين الأول',
    en: 'October',
  },
  {
    ar: 'تشرين الثاني',
    en: 'November',
  },
  {
    ar: 'كانون الأول',
    en: 'December',
  },
]

const getMonthName = (monthIndex: number, language: string) => {
  if (monthIndex >= 0 && monthIndex <= 11) {
    const month = monthNames[monthIndex]

    return month[language as keyof Month]
  }

  return null
}

export default getMonthName
