import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useRouter, RouterProps } from './useRouter'
import Layout from 'components/Layout/Layout'
import Home from 'screens/Home/Home'
import About from 'screens/About/About'
import OurBranches from 'screens/OurBranches/OurBranches'
import BranchDetails from 'screens/BranchDetails/BranchDetails'
import OurTeam from 'screens/OurTeam/OurTeam'
import Contact from 'screens/Contact/Contact'
import Posts from 'screens/Posts/Posts'
import PostDetails from 'screens/PostDetails/PostDetails'

const Router: React.FC<RouterProps> = (props: RouterProps) => {
  const {} = useRouter(props)

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/OurBranches' element={<OurBranches />} />
          <Route path='/OurTeam' element={<OurTeam />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Posts' element={<Posts />} />
          <Route path='/PostDetails/:slug' element={<PostDetails />} />
          <Route path='/BranchDetails/:slug' element={<BranchDetails />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default Router
