import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import IPostsResponse from 'models/Posts/IPosts'
import IPostsCountResponse from 'models/Posts/IPostsCount'
import IPostCategoryResponse from 'models/Posts/IPostCategory'
import IPostTagResponse from 'models/Posts/IPostTag'
import IPostDetailsResponse from 'models/Posts/IPostDetails'

export const postsService = {
  getLatestPosts: async (): Promise<IPostsResponse> => {
    try {
      const response: IPostsResponse = await makeRequest(
        'GET',
        ENDPOINTS.Posts.GetLatestPosts
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get latest posts data failed')
    }
  },
  getPostsCount: async (
    searchTerm: string,
    categoryId: string,
    tagId: string,
    isAr: boolean
  ): Promise<IPostsCountResponse> => {
    try {
      const searchTermFilter = searchTerm
        ? isAr
          ? `[title.ar match "*${searchTerm}*"]`
          : `[title.en match "*${searchTerm}*"]`
        : ''
      const categoryFilter =
        categoryId && categoryId !== '0'
          ? `[category->_id match "${categoryId}"]`
          : ''
      const tagFilter =
        tagId && tagId !== '0' ? `["${tagId}" in tags[]->_id]` : ''

      const response: IPostsCountResponse = await makeRequest(
        'GET',
        `${ENDPOINTS.Posts.GetPostsCountPrefix}${searchTermFilter}${categoryFilter}${tagFilter}${ENDPOINTS.Posts.GetPostsCountSuffix}`
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get posts count failed')
    }
  },
  getPaginatedPosts: async (
    from: number,
    to: number,
    searchTerm: string,
    categoryId: string,
    tagId: string,
    isAr: boolean
  ): Promise<IPostsResponse> => {
    try {
      const searchTermFilter = searchTerm
        ? isAr
          ? `[title.ar match "*${searchTerm}*"]`
          : `[title.en match "*${searchTerm}*"]`
        : ''
      const categoryFilter =
        categoryId && categoryId !== '0'
          ? `[category->_id match "${categoryId}"]`
          : ''
      const tagFilter =
        tagId && tagId !== '0' ? `["${tagId}" in tags[]->_id]` : ''

      const query = `*[_type == "post"]${searchTermFilter}${categoryFilter}${tagFilter} | order(publishedAt desc)[${from}..${to}]{ 
        "id": _id, 
        "updatedAt": _updatedAt, 
        "titleAr": title.ar, 
        "titleEn": title.en, 
        "slugAr": slug.ar.current, 
        "slugEn": slug.en.current, 
        "image": image.asset->url,
        "descriptionAr": description.ar,
        "descriptionEn": description.en,
        "featured": featured, 
        "publishedAt": publishedAt,
        author->{ 
          "id": _id, 
          "nameAr": name.ar, 
          "nameEn": name.en, 
          "photo": photo.asset->url
        },
        category->{ 
          "id": _id, 
          "nameAr": name.ar, 
          "nameEn": name.en 
        },
        tags[]->{ 
          "id": _id,
        }
      }`

      const response: IPostsResponse = await makeRequest(
        'GET',
        `${ENDPOINTS.Posts.GetPaginatedPosts}?query=${query}`
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get posts failed')
    }
  },
  getPostCategories: async (): Promise<IPostCategoryResponse> => {
    try {
      const response: IPostCategoryResponse = await makeRequest(
        'GET',
        ENDPOINTS.Posts.GetPostCategories
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get post categories failed')
    }
  },
  getPostTags: async (): Promise<IPostTagResponse> => {
    try {
      const response: IPostTagResponse = await makeRequest(
        'GET',
        ENDPOINTS.Posts.GetPostTags
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get post tags failed')
    }
  },
  getPostDetails: async (slug: string): Promise<IPostDetailsResponse> => {
    try {
      const response: IPostDetailsResponse = await makeRequest(
        'GET',
        `${ENDPOINTS.Posts.GetPostDetailsPrefix}${slug}${ENDPOINTS.Posts.GetPostDetailsSuffix}`
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get post details failed')
    }
  },
  getFeaturedPosts: async (): Promise<IPostsResponse> => {
    try {
      const response: IPostsResponse = await makeRequest(
        'GET',
        ENDPOINTS.Posts.GetFeaturedPosts
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get featured posts data failed')
    }
  },
}
