import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import IOurBranchesResponse from 'models/Branches/IOurBranches'

export const branchesService = {
  getOurBranches: async (): Promise<IOurBranchesResponse> => {
    try {
      const response: IOurBranchesResponse = await makeRequest(
        'GET',
        ENDPOINTS.Branches.GetOurBranches
      )

      return response
    } catch (error) {
      throw new Error(`Get our branches data failed: ${error}`)
    }
  },
  getBranchDetails: async (slug: string): Promise<IOurBranchesResponse> => {
    try {
      const response: IOurBranchesResponse = await makeRequest(
        'GET',
        `${ENDPOINTS.Branches.GetBranchDetailsPrefix}${slug}${ENDPOINTS.Branches.GetBranchDetailsSuffix}`
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Get post details failed')
    }
  },
}
