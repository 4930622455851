import React, { useEffect } from 'react'
import './App.scss'
import Router from 'router/Router'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import { switchBootstrapCss } from 'utils/switchBootstrapCss'

function App() {
  const { i18n } = useCustomTranslation()

  useEffect(() => {
    const handleLanguageChange = (lang: string) => {
      const isRtl = lang === 'ar'
      switchBootstrapCss(isRtl)
    }

    handleLanguageChange(i18n.language)

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n])

  return (
    <div className='App'>
      <Router />
    </div>
  )
}

export default App
