import React, { memo } from 'react'
import { useAuthorCard, AuthorCardProps } from './useAuthorCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'fontawesome'
import styles from './AuthorCard.module.scss'

const AuthorCard: React.FC<AuthorCardProps> = (props: AuthorCardProps) => {
  const { title, name, description, url, photo, isAr } = useAuthorCard(props)

  return (
    <div className={styles['author-card']}>
      <h4 className={styles.title}>{title}</h4>
      <div className='d-flex flex-column align-items-center'>
        {photo ? (
          <img
            src={photo}
            width={160}
            className='rounded-circle flex-shrink-0'
            alt='author image'
          />
        ) : (
          <FontAwesomeIcon icon='pen-nib' color='#035373' className='fs-1' />
        )}
        {url ? (
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className={styles.link}
          >
            <h4>
              {name}
              <FontAwesomeIcon
                icon={['fas', isAr ? 'arrow-left' : 'arrow-right']}
                className='ms-2 fs-6'
              />
            </h4>
          </a>
        ) : (
          <h4>{name}</h4>
        )}
        <p
          className='mb-0'
          dangerouslySetInnerHTML={{
            __html: Array.isArray(description)
              ? blockContentToHtml({
                  blocks: description,
                })
              : `<p>${description}</p>`,
          }}
        ></p>
      </div>
    </div>
  )
}

export default memo(AuthorCard)
