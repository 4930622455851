import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getPostDetails,
  getLatestPosts,
  clearPostDetails,
} from 'actions/posts.action'
import { RootState } from 'reducers/rootReducer'
import { IPosts } from 'models/Posts/IPosts'
import ITag from 'models/Common/ITag'

export interface PostDetailsProps {}

export const usePostDetails = (props: PostDetailsProps) => {
  const dispatch = useAppDispatch()

  const { slug } = useParams<{ slug: string }>()

  const [latestPosts, setLatestPosts] = useState<IPosts[]>([])
  const [tags, setTags] = useState<ITag[]>([])

  const postDetails = useSelector((state: RootState) => state.posts.postDetails)
  const latestPostsData = useSelector(
    (state: RootState) => state.posts.latestPostsData
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!postDetails && slug) dispatch(getPostDetails(slug))
  }, [postDetails, slug])

  useEffect(() => {
    if (!latestPostsData) dispatch(getLatestPosts())

    if (latestPostsData && latestPostsData.length) {
      const latestNewsTemp = latestPostsData.filter(
        (post) => post.slugAr !== slug && post.slugEn !== slug
      )

      setLatestPosts(latestNewsTemp)
    }
  }, [latestPostsData, slug])

  useEffect(() => {
    if (postDetails && postDetails.length) setTags(postDetails[0].tags)
  }, [postDetails])

  useEffect(() => {
    return () => {
      dispatch(clearPostDetails())
    }
  }, [dispatch, slug])

  return {
    ...props,
    postDetails,
    latestPosts,
    tags,
  }
}
