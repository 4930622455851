import React from 'react'
import { usePostDetails, PostDetailsProps } from './usePostDetails'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import styles from './PostDetails.module.scss'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import AuthorCard from 'components/AuthorCard/AuthorCard'
import Details from './Details/Details'
import LatestPosts from './LatestPosts/LatestPosts'
import Tags from './Tags/Tags'

const PostDetails: React.FC<PostDetailsProps> = (props: PostDetailsProps) => {
  const { postDetails, latestPosts, tags } = usePostDetails(props)

  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  return (
    <main>
      <InnerTitleSection
        title={t('postDetails')}
        breadcrumbs={[
          {
            title: t('navHome'),
            link: '/',
          },
          {
            title: t('navPosts'),
            link: '/Posts',
          },
          {
            title: t('postDetails'),
          },
        ]}
      />
      <article className={styles['post-details']}>
        <div className='container'>
          {postDetails && postDetails.length && (
            <div className='row'>
              <Details details={postDetails} isAr={isAr} styles={styles} />
              <div className='col-lg-4 col-xl-3'>
                <AuthorCard
                  title={t('author')}
                  name={
                    isAr
                      ? postDetails[0].author.nameAr
                      : postDetails[0].author.nameEn
                  }
                  description={
                    isAr
                      ? postDetails[0].author.bioAr
                      : postDetails[0].author.bioEn
                  }
                  url={
                    postDetails[0].author.url ? postDetails[0].author.url : null
                  }
                  photo={postDetails[0].author.photo}
                  isAr={isAr}
                />
                <LatestPosts posts={latestPosts} isAr={isAr} t={t} />
                <Tags tags={tags} isAr={isAr} />
              </div>
            </div>
          )}
        </div>
      </article>
    </main>
  )
}

export default PostDetails
