import React from 'react'
import { useBranchDetails, BranchDetailsProps } from './useBranchDetails'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import BranchTitle from './BranchTitle/BranchTitle'
import ImagesGallery from './ImagesGallery/ImagesGallery'
import BranchDescription from './BranchDescription/BranchDescription'
import AnnualActivities from './AnnualActivities/AnnualActivities'
import BranchStats from './BranchStats/BranchStats'
import BranchNotFound from './BranchNotFound/BranchNotFound'

const BranchDetails: React.FC<BranchDetailsProps> = (
  props: BranchDetailsProps
) => {
  const { isLoading, branchDetails } = useBranchDetails(props)

  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  return (
    <main>
      <InnerTitleSection
        title={t('branchDetails')}
        breadcrumbs={[
          {
            title: t('navHome'),
            link: '/',
          },
          {
            title: t('navOurBranches'),
            link: '/Posts',
          },
          {
            title: t('branchDetails'),
          },
        ]}
      />
      {branchDetails && branchDetails.length ? (
        <div>
          <BranchTitle
            title={isAr ? branchDetails[0].titleAr : branchDetails[0].titleEn}
            subTitle={
              isAr ? branchDetails[0].subTitleAr : branchDetails[0].subTitleEn
            }
          />
          <ImagesGallery gallery={branchDetails[0].imagesGallery} />
          <BranchDescription
            description={
              isAr
                ? branchDetails[0].descriptionAr
                : branchDetails[0].descriptionEn
            }
          />
          <AnnualActivities
            title={t('annualActivities')}
            activities={
              isAr
                ? branchDetails[0].annualActivitiesAr
                : branchDetails[0].annualActivitiesEn
            }
          />
          <BranchStats
            title={t('additionalBranchInfo')}
            yearOfFoundationTitle={t('yearOfFoundation')}
            numberOfVolunteersTitle={t('numberOfVolunteers')}
            yearOfFoundation={branchDetails[0].yearOfFoundation}
            numberOfVolunteers={branchDetails[0].numberOfVolunteers}
          />
        </div>
      ) : (
        <BranchNotFound
          message={isLoading ? `${t('loading')}...` : t('noData')}
        />
      )}
    </main>
  )
}

export default BranchDetails
