export interface BranchStatsProps {
  title: string
  yearOfFoundationTitle: string
  numberOfVolunteersTitle: string
  yearOfFoundation: number
  numberOfVolunteers: number
}

export const useBranchStats = (props: BranchStatsProps) => {
  return {
    ...props,
  }
}
