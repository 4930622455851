import { useCustomTranslation } from 'hooks/useCustomTranslation'

export interface LanguageSwitcherProps {}

export const useLanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { i18n, t } = useCustomTranslation()

  const currentLanguage = i18n.language

  const handleChangeLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en'

    i18n.changeLanguage(newLanguage)

    document.documentElement.dir = newLanguage === 'ar' ? 'rtl' : 'ltr'
    document.documentElement.lang = newLanguage === 'ar' ? 'ar' : 'en'
  }

  return {
    ...props,
    currentLanguage,
    t,
    handleChangeLanguage,
  }
}
