import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getAbout as getAboutReducer,
} from 'reducers/about.reducer'
import { aboutService } from 'services/about.service'

export const getAbout = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await aboutService.getAbout()
    if (response) {
      const aboutData = response

      dispatch(getAboutReducer({ aboutData }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}
