import React from 'react'
import { useTags, TagsProps } from './useTags'
import Tag from 'components/Tag/Tag'

const Tags: React.FC<TagsProps> = (props: TagsProps) => {
  const { tags, isAr } = useTags(props)

  return (
    <div className='d-flex flex-wrap mt-4'>
      {tags &&
        tags.length &&
        tags.map((tag, index) => (
          <Tag
            key={`tag-${tag.id}-${index}`}
            name={isAr ? tag.tagAr : tag.tagEn}
          />
        ))}
    </div>
  )
}

export default Tags
