import React, { useEffect } from 'react'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import Card from './Card/Card'
import Form from './Form/Form'
import Map from './Map/Map'

const Contact: React.FC = () => {
  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <InnerTitleSection
        title={t('navContactUs')}
        breadcrumbs={[
          {
            title: t('navHome'),
            link: '/',
          },
          {
            title: t('navContactUs'),
          },
        ]}
      />
      <div className='container'>
        <div className='row py-4 py-lg-5'>
          <div className={`col-lg-5 ${isAr ? 'pe-lg-5' : 'ps-lg-5 pe-2'}`}>
            <Card isAr={isAr} t={t} />
          </div>
          <div className='col-lg-7'>
            <Form t={t} />
          </div>
        </div>
        <Map />
      </div>
    </main>
  )
}

export default Contact
