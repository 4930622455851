import React from 'react'
import { useAbout, AboutProps } from './useAbout'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './About.module.scss'

const About: React.FC<AboutProps> = (props: AboutProps) => {
  const { isAr, socialMedia, contactSocials, t } = useAbout(props)

  return (
    <div className={`col-lg-6 col-xl-5 ${styles['footer-about']}`}>
      <Link to='/' className='logo d-flex align-items-center'>
        <img
          src='/img/Logos/logo-white.png'
          alt='logo'
          height={64}
          className='d-none d-xl-block'
        />
        <img
          src='/img/Logos/logo-squared.png'
          alt='logo'
          height={64}
          className='d-xl-none'
        />
      </Link>
      <div className={`${styles['footer-contact']} pt-3`}>
        {contactSocials && contactSocials.length ? (
          contactSocials.map((item, index) => {
            return (
              <p
                key={`footer-contact-${item.id}-${index}`}
                className={styles.paragraph}
              >
                <strong>{isAr ? item.nameAr : item.nameEn}:</strong>&nbsp;
                {item.displayedEn.indexOf('@') > 0 ? (
                  <a
                    href={`mailto:${item.displayedEn}`}
                    className='text-white text-decoration-none text-hover-secondary transition-0-4'
                  >
                    {item.displayedEn}
                  </a>
                ) : isAr ? (
                  item.displayedAr
                ) : (
                  item.displayedEn
                )}
              </p>
            )
          })
        ) : (
          <div className='col-12 text-center fs-4 fw-bold'>{t('noData')}</div>
        )}
      </div>
      <div className={`${styles['social-links']} d-flex mt-4 mb-4 mb-lg-0`}>
        {socialMedia &&
          socialMedia.length &&
          socialMedia.map((thePlatform, index) => (
            <a
              key={`social-media-${thePlatform.id}-${index}`}
              href={thePlatform.url}
              className={styles.link}
              target='_blank'
              referrerPolicy='no-referrer'
              title={thePlatform.platform}
            >
              <FontAwesomeIcon
                icon={[
                  (thePlatform.iconPrefix as IconPrefix) || 'fas',
                  thePlatform.icon as IconName,
                ]}
              />
            </a>
          ))}
      </div>
    </div>
  )
}

export default About
