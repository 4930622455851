import React from 'react'
import {
  useLanguageSwitcher,
  LanguageSwitcherProps,
} from './useLanguageSwitcher'
import styles from './LanguageSwitcher.module.scss'

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (
  props: LanguageSwitcherProps
) => {
  const { currentLanguage, t, handleChangeLanguage } =
    useLanguageSwitcher(props)

  return (
    <button
      className={styles['btn-language']}
      dir='ltr'
      onClick={handleChangeLanguage}
    >
      <img
        src={
          currentLanguage === 'en' ? '/img/Flags/lb.svg' : '/img/Flags/gb.svg'
        }
        alt='language flag'
        height={20}
        className={styles.flag}
      />
      {t('btnLanguageLabel')}
    </button>
  )
}

export default LanguageSwitcher
