import React from 'react'
import { useFooter, FooterProps } from './useFooter'
import About from './About/About'
import Links from './Links/Links'
import Copyright from './Copyright/Copyright'
import LatestPosts from './LatestPosts/LatestPosts'
import styles from './Footer.module.scss'

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { isAr, socialMedia, contactSocials, t } = useFooter(props)

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles['footer-top']}`}>
        <div className='row gy-4'>
          <About
            isAr={isAr}
            socialMedia={socialMedia}
            contactSocials={contactSocials}
            t={t}
          />
          <Links t={t} />
          <LatestPosts isAr={isAr} t={t} />
        </div>
      </div>
      <Copyright t={t} />
    </footer>
  )
}

export default Footer
