import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface ServicesProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useServices = (props: ServicesProps) => {
  const servicesData = useSelector((state: RootState) => state.about.services)

  return {
    ...props,
    servicesData,
  }
}
