import { createSlice } from '@reduxjs/toolkit'
import { ISlider } from 'models/Home/ISlider'
import { IPartner } from 'models/Home/IPartner'
import { ISocialMedia } from 'models/Home/ISocialMedia'

export interface HomeState {
  isLoading: boolean
  isGetSliderSuccess: boolean
  isGetAboutSuccess: boolean
  isGetPartnersSuccess: boolean
  isGetSocialMediaSuccess: boolean
  sliderData: ISlider[] | null
  partnersData: IPartner[] | null
  socialMedia: ISocialMedia[] | null
}

const initialState: HomeState = {
  isLoading: false,
  isGetSliderSuccess: false,
  isGetAboutSuccess: false,
  isGetPartnersSuccess: false,
  isGetSocialMediaSuccess: false,
  sliderData: null,
  partnersData: null,
  socialMedia: null,
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getSlider(state, action) {
      state.isLoading = false
      state.isGetSliderSuccess = true
      state.sliderData = action.payload.sliderData.result
    },
    getPartners(state, action) {
      state.isLoading = false
      state.isGetPartnersSuccess = true
      state.partnersData = action.payload.partnersData.result
    },
    getSocialMedia(state, action) {
      state.isLoading = false
      state.isGetSocialMediaSuccess = true
      state.socialMedia = action.payload.socialMedia.result
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  getSlider,
  getPartners,
  getSocialMedia,
} = homeSlice.actions

export default homeSlice.reducer
