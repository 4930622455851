export interface TagProps {
  name: string
  isSmall?: boolean
}

export const useTag = (props: TagProps) => {
  return {
    ...props,
  }
}
