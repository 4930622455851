import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getAbout } from 'actions/about.action'
import { RootState } from 'reducers/rootReducer'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import Services from './Services/Services'
import Goals from './Goals/Goals'
import Values from './Values/Values'

const About: React.FC = () => {
  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  const dispatch = useAppDispatch()

  const aboutData = useSelector((state: RootState) => state.about.aboutData)

  useEffect(() => {
    if (!aboutData) dispatch(getAbout())
  }, [aboutData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <InnerTitleSection
        title={t('navAboutUs')}
        breadcrumbs={[
          {
            title: t('navHome'),
            link: '/',
          },
          {
            title: t('navAboutUs'),
          },
        ]}
      />
      <WhoWeAre isAr={isAr} t={t} />
      <Goals isAr={isAr} t={t} />
      <Values isAr={isAr} t={t} />
      <Services isAr={isAr} t={t} />
    </main>
  )
}

export default About
