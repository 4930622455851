import React from 'react'
import { useMap, MapProps } from './useMap'

const Map: React.FC<MapProps> = (props: MapProps) => {
  const {} = useMap(props)

  return (
    <div className='row'>
      <div className='col-12'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus'
          frameBorder='0'
          title='Address'
          style={{
            border: 0,
            width: '100%',
            height: '400px',
            borderRadius: '10px',
          }}
          allowFullScreen
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
    </div>
  )
}

export default Map
