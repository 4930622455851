import React, { memo } from 'react'
import { useTeamCard, TeamCardProps } from './useTeamCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './TeamCard.module.scss'

const TeamCard: React.FC<TeamCardProps> = (props: TeamCardProps) => {
  const { image, name, role, bio, socialLinks } = useTeamCard(props)

  return (
    <div className={styles['team-card']}>
      <div className={styles.pic}>
        <img src={image} className='img-fluid' alt={name} />
      </div>
      <div className={styles.info}>
        <h4>{name}</h4>
        <span>{role}</span>
        <div
          dangerouslySetInnerHTML={{
            __html: Array.isArray(bio)
              ? blockContentToHtml({
                  blocks: bio,
                })
              : `<p>${bio}</p>`,
          }}
        ></div>
        <div className={styles.social}>
          {socialLinks &&
            socialLinks.length &&
            socialLinks.map((thePlatform, index) => (
              <a
                key={`social-media-${thePlatform.id}-${index}`}
                href={thePlatform.url}
                target='_blank'
                referrerPolicy='no-referrer'
                title={thePlatform.platform}
              >
                <FontAwesomeIcon
                  icon={[
                    (thePlatform.iconPrefix as IconPrefix) || 'fas',
                    thePlatform.icon as IconName,
                  ]}
                  className={styles.icon}
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  )
}

export default memo(TeamCard)
