import { TranslationKeys } from 'types/translationKeys'
import { IPosts } from 'models/Posts/IPosts'

export interface LoadMoreProps {
  posts: IPosts[] | null
  postsCount: number
  isLoading: boolean
  t: (key: TranslationKeys) => string
  handleLoadMore: () => void
}

export const useLoadMore = (props: LoadMoreProps) => {
  return {
    ...props,
  }
}
