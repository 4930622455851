import { TranslationKeys } from 'types/translationKeys'

export interface LinksProps {
  t: (key: TranslationKeys) => string
}

export const useLinks = (props: LinksProps) => {
  return {
    ...props,
  }
}
