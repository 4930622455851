import React, { useEffect } from 'react'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import Hero from './Hero/Hero'
import About from './About/About'
import FeaturedPosts from './FeaturedPosts/FeaturedPosts'
import LatestPosts from './LatestPosts/LatestPosts'
import Partners from './Partners/Partners'

const Home: React.FC = () => {
  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <Hero isAr={isAr} />
      <FeaturedPosts isAr={isAr} t={t} />
      <About isAr={isAr} t={t} />
      <LatestPosts isAr={isAr} t={t} />
      <Partners isAr={isAr} t={t} />
    </main>
  )
}

export default Home
