import { createSlice } from '@reduxjs/toolkit'
import { IOurBranches } from 'models/Branches/IOurBranches'

export interface BranchesState {
  isLoading: boolean
  isGetOurBranchesSuccess: boolean
  isGetBranchDetailsSuccess: boolean
  ourBranches: IOurBranches[] | null
  branchDetails: IOurBranches[] | null
}

const initialState: BranchesState = {
  isLoading: false,
  isGetOurBranchesSuccess: false,
  isGetBranchDetailsSuccess: false,
  ourBranches: null,
  branchDetails: null,
}

const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    clearBranchDetails: (state) => {
      state.isGetBranchDetailsSuccess = false
      state.branchDetails = null
    },
    getOurBranches(state, action) {
      state.isLoading = false
      state.isGetOurBranchesSuccess = true
      state.ourBranches = action.payload.ourBranches.result
    },
    getBranchDetails(state, action) {
      state.isLoading = false
      state.isGetBranchDetailsSuccess = true
      state.branchDetails = action.payload.branchDetails.result
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  clearBranchDetails,
  getOurBranches,
  getBranchDetails,
} = branchesSlice.actions

export default branchesSlice.reducer
