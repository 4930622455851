import { IPosts } from 'models/Posts/IPosts'
import { TranslationKeys } from 'types/translationKeys'

export interface LatestPostsProps {
  posts: IPosts[] | null
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useLatestPosts = (props: LatestPostsProps) => {
  return {
    ...props,
  }
}
