import React from 'react'
import { useCard, CardProps } from './useCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './Card.module.scss'

const Card: React.FC<CardProps> = (props: CardProps) => {
  const { contactSocials, isAr, t } = useCard(props)

  return (
    <div className={styles['contact-card']}>
      <h3>{t('ourInformation')}</h3>
      {contactSocials && contactSocials.length ? (
        contactSocials.map((item, index) => {
          return (
            <div
              key={`social-item-${item.id}-${index}`}
              className={styles.item}
            >
              <div className={styles['icon-box']}>
                <FontAwesomeIcon
                  icon={[
                    (item.iconPrefix as IconPrefix) || 'fas',
                    item.icon as IconName,
                  ]}
                />
              </div>
              <div className={styles.content}>
                <h4>{isAr ? item.nameAr : item.nameEn}</h4>
                <p>
                  {item.displayedEn.indexOf('@') > 0 ? (
                    <a href={`mailto:${item.displayedEn}`}>
                      {item.displayedEn}
                    </a>
                  ) : isAr ? (
                    item.displayedAr
                  ) : (
                    item.displayedEn
                  )}
                </p>
              </div>
            </div>
          )
        })
      ) : (
        <div className='col-12 text-center fs-4 fw-bold'>{t('noData')}</div>
      )}
    </div>
  )
}

export default Card
