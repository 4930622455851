import { combineReducers } from 'redux'
import homeReducer, { HomeState } from './home.reducer'
import aboutReducer, { AboutState } from './about.reducer'
import postsReducer, { PostsState } from './posts.reducer'
import contactReducer, { ContactState } from './contact.reducer'
import teamReducer, { TeamState } from './team.reducer'
import branchesReducer, { BranchesState } from './branches.reducer'

const rootReducer = combineReducers({
  home: homeReducer,
  about: aboutReducer,
  posts: postsReducer,
  contact: contactReducer,
  team: teamReducer,
  branches: branchesReducer,
})

export type RootState = {
  home: HomeState
  about: AboutState
  posts: PostsState
  contact: ContactState
  team: TeamState
  branches: BranchesState
}

export default rootReducer
