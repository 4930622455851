import { createSlice } from '@reduxjs/toolkit'
import { IOurTeam } from 'models/Team/IOurTeam'

export interface TeamState {
  isLoading: boolean
  isGetOurTeamSuccess: boolean
  ourTeam: IOurTeam[] | null
}

const initialState: TeamState = {
  isLoading: false,
  isGetOurTeamSuccess: false,
  ourTeam: null,
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getOurTeam(state, action) {
      state.isLoading = false
      state.isGetOurTeamSuccess = true
      state.ourTeam = action.payload.ourTeam.result
    },
  },
})

export const { apiRequest, apiFailure, getOurTeam } = teamSlice.actions

export default teamSlice.reducer
