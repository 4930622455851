import { IPostDetails } from 'models/Posts/IPostDetails'

export interface DetailsProps {
  details: IPostDetails[]
  isAr: boolean
  styles: {
    readonly [key: string]: string
  }
}

export const useDetails = (props: DetailsProps) => {
  return {
    ...props,
  }
}
