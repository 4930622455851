export const switchBootstrapCss = (isRtl: boolean) => {
  const head = document.head

  const fontFamilyLink = document.querySelector(
    'link[href*="Changa/stylesheet.css"]'
  ) as HTMLLinkElement

  const existingLink = document.getElementById(
    'bootstrap-css'
  ) as HTMLLinkElement

  if (existingLink) {
    head.removeChild(existingLink)
  }

  const link = document.createElement('link')

  link.id = 'bootstrap-css'
  link.rel = 'stylesheet'
  link.href = isRtl
    ? 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css'
    : 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css'

  if (fontFamilyLink) {
    head.insertBefore(link, fontFamilyLink)
  } else {
    head.appendChild(link)
  }
}
