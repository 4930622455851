import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  paginatedPostsRequest,
  getLatestPosts as getLatestPostsReducer,
  getFeaturedPosts as getFeaturedPostsReducer,
  getPostsCount as getPostsCountReducer,
  getPaginatedPostsSearch as getPaginatedPostsReducerSearch,
  getPaginatedPostsLoadMore as getPaginatedPostsLoadMoreReducer,
  getPostCategories as getPostCategoriesReducer,
  getPostTags as getPostTagsReducer,
  getPostDetails as getPostDetailsReducer,
  clearPostDetails as clearPostDetailsReducer,
} from 'reducers/posts.reducer'
import { postsService } from 'services/posts.service'

export const getLatestPosts = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await postsService.getLatestPosts()
    if (response) {
      const latestPostsData = response

      dispatch(getLatestPostsReducer({ latestPostsData }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getPostsCount =
  (searchTerm: string, categoryId: string, tagId: string, isAr: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await postsService.getPostsCount(
        searchTerm,
        categoryId,
        tagId,
        isAr
      )
      if (response) {
        const postsCount = response

        dispatch(getPostsCountReducer({ postsCount }))
      }
    } catch (error) {
      console.error(error)
      dispatch(apiFailure())
    }
  }

export const getPaginatedPosts =
  (
    from: number,
    to: number,
    searchTerm: string,
    categoryId: string,
    tagId: string,
    isAr: boolean
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(paginatedPostsRequest())

      const response = await postsService.getPaginatedPosts(
        from,
        to,
        searchTerm,
        categoryId,
        tagId,
        isAr
      )
      if (response) {
        const paginatedPosts = response

        if (from === 0)
          dispatch(getPaginatedPostsReducerSearch({ paginatedPosts }))
        else dispatch(getPaginatedPostsLoadMoreReducer({ paginatedPosts }))
      }
    } catch (error) {
      console.error(error)
      dispatch(apiFailure())
    }
  }

export const getPostTags = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await postsService.getPostTags()
    if (response) {
      const postTags = response

      dispatch(getPostTagsReducer({ postTags }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getPostCategories = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await postsService.getPostCategories()
    if (response) {
      const postCategories = response

      dispatch(getPostCategoriesReducer({ postCategories }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getPostDetails = (slug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await postsService.getPostDetails(slug)
    if (response) {
      const postDetails = response

      dispatch(getPostDetailsReducer({ postDetails }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const clearPostDetails = () => (dispatch: Dispatch) => {
  try {
    dispatch(clearPostDetailsReducer())
  } catch (error) {
    console.error(`Could not clear post details state: ${error}`)
  }
}

export const getFeaturedPosts = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await postsService.getFeaturedPosts()
    if (response) {
      const featuredPostsData = response

      dispatch(getFeaturedPostsReducer({ featuredPostsData }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}
