import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getFeaturedPosts } from 'actions/posts.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface FeaturedPostsProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useFeaturedPosts = (props: FeaturedPostsProps) => {
  const dispatch = useAppDispatch()

  const featuredPostsData = useSelector(
    (state: RootState) => state.posts.featuredPostsData
  )

  useEffect(() => {
    if (!featuredPostsData) dispatch(getFeaturedPosts())
  }, [featuredPostsData])

  return {
    ...props,
    featuredPostsData,
  }
}
