import React, { Fragment } from 'react'
import { useForm, FormProps } from './useForm'
import styles from './Form.module.scss'

const Form: React.FC<FormProps> = (props: FormProps) => {
  const {
    contactFormData,
    isLoading,
    isSendContactMessageSuccess,
    isSendContactMessageFailed,
    t,
    handleControllerChange,
    handleFormSubmit,
  } = useForm(props)

  return (
    <div className={styles['contact-form']}>
      <h3>{t('getInTouch')}</h3>
      <form onSubmit={handleFormSubmit}>
        <div className='row gy-4'>
          <div className='col-md-6'>
            <input
              type='text'
              name='name'
              className='form-control'
              placeholder={t('labelName')}
              title={t('requiredInput')}
              value={contactFormData.name}
              onChange={handleControllerChange}
              required
            />
          </div>
          <div className='col-md-6 '>
            <input
              type='email'
              className='form-control'
              name='email'
              placeholder={t('labelEmail')}
              title={t('requiredInput')}
              value={contactFormData.email}
              onChange={handleControllerChange}
              required
            />
          </div>
          <div className='col-12'>
            <input
              type='text'
              className='form-control'
              name='subject'
              placeholder={t('labelSubject')}
              title={t('requiredInput')}
              value={contactFormData.subject}
              onChange={handleControllerChange}
              required
            />
          </div>
          <div className='col-12'>
            <textarea
              className='form-control'
              name='message'
              rows={6}
              placeholder={t('labelMessage')}
              title={t('requiredInput')}
              value={contactFormData.message}
              onChange={handleControllerChange}
              required
            ></textarea>
          </div>
          <div className='col-12 text-center'>
            {isSendContactMessageFailed ? (
              <div className={`${styles['error-message']} d-none`}></div>
            ) : (
              <Fragment />
            )}
            {isSendContactMessageSuccess ? (
              <div className={`${styles['sent-message']} d-none`}>
                {t('contactMessageSuccess')}
              </div>
            ) : (
              <Fragment />
            )}
            <button
              type='submit'
              className='btn btn-primary px-5 py-2'
              disabled={isLoading}
            >
              {isLoading ? `${t('loadingMessage')}...` : t('send')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Form
