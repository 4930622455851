import React from 'react'
import { useBranchNotFound, BranchNotFoundProps } from './useBranchNotFound'

const BranchNotFound: React.FC<BranchNotFoundProps> = (
  props: BranchNotFoundProps
) => {
  const { message } = useBranchNotFound(props)

  return (
    <div className='container py-5'>
      <section>
        <div className='row'>
          <div className='col-12 text-center fs-4 fw-bold'>{message}</div>
        </div>
      </section>
    </div>
  )
}

export default BranchNotFound
