import React, { memo, Fragment } from 'react'
import { useServiceCard, ServiceCardProps } from './useServiceCard'
import blockContentToHtml from '@sanity/block-content-to-html'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import 'fontawesome'
import styles from './ServiceCard.module.scss'

const ServiceCard: React.FC<ServiceCardProps> = (props: ServiceCardProps) => {
  const { title, description, icon, iconPrefix } = useServiceCard(props)

  return (
    <div className={`${styles['service-card']} d-flex`}>
      <div className={`${styles.icon} flex-shrink-0`}>
        {icon && iconPrefix ? (
          <FontAwesomeIcon
            icon={[(iconPrefix as IconPrefix) || 'fas', icon as IconName]}
          />
        ) : (
          <Fragment />
        )}
      </div>
      <div>
        <h3>{title}</h3>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: Array.isArray(description)
              ? blockContentToHtml({
                  blocks: description,
                })
              : `<p>${description}</p>`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default memo(ServiceCard)
