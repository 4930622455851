import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  clearBranchDetails as clearBranchDetailsReducer,
  getOurBranches as getOurBranchesReducer,
  getBranchDetails as getBranchDetailsReducer,
} from 'reducers/branches.reducer'
import { branchesService } from 'services/branches.service'

export const getOurBranches = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await branchesService.getOurBranches()
    if (response) {
      const ourBranches = response

      dispatch(getOurBranchesReducer({ ourBranches }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getBranchDetails =
  (slug: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await branchesService.getBranchDetails(slug)
      if (response) {
        const branchDetails = response

        dispatch(getBranchDetailsReducer({ branchDetails }))
      }
    } catch (error) {
      console.error(error)
      dispatch(apiFailure())
    }
  }

export const clearBranchDetails = () => (dispatch: Dispatch) => {
  try {
    dispatch(clearBranchDetailsReducer())
  } catch (error) {
    console.error(`Could not clear branch details state: ${error}`)
  }
}
