import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getOurBranches } from 'actions/branches.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface MapProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useMap = (props: MapProps) => {
  const dispatch = useAppDispatch()

  const ourBranches = useSelector(
    (state: RootState) => state.branches.ourBranches
  )

  useEffect(() => {
    if (!ourBranches) dispatch(getOurBranches())
  }, [ourBranches])

  return {
    ...props,
    ourBranches,
  }
}
