import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getContactSocials } from 'actions/contact.action'
import { RootState } from 'reducers/rootReducer'
import { TranslationKeys } from 'types/translationKeys'

export interface CardProps {
  isAr: boolean
  t: (key: TranslationKeys) => string
}

export const useCard = (props: CardProps) => {
  const dispatch = useAppDispatch()

  const contactSocials = useSelector(
    (state: RootState) => state.contact.contactSocials
  )

  useEffect(() => {
    if (!contactSocials) dispatch(getContactSocials())
  }, [contactSocials])

  return {
    ...props,
    contactSocials,
  }
}
