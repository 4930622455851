import React from 'react'
import { useMap, MapProps } from './useMap'
import { Link } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'fontawesome'

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
})

const Map: React.FC<MapProps> = (props: MapProps) => {
  const { ourBranches, isAr, t } = useMap(props)

  return (
    <section>
      <div className='container'>
        <div className='row'>
          {ourBranches && ourBranches.length ? (
            <div className='col-12 py-5'>
              <MapContainer
                center={[33.6547, 35.4623]}
                zoom={9}
                style={{ height: '70vh', width: '100%', borderRadius: '10px' }}
              >
                <TileLayer
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {ourBranches.map((branch, index) => {
                  return (
                    <Marker
                      key={`branch-${branch.id}-${index}`}
                      position={[branch.location.lat, branch.location.lng]}
                    >
                      <Popup>
                        <div
                          dir={isAr ? 'rtl' : 'ltr'}
                          style={{ textAlign: 'start', fontFamily: 'Changa' }}
                        >
                          <div className='d-flex flex-column align-items-center'>
                            <img
                              src={branch.featuredImage}
                              alt={`${branch.titleAr}`}
                              width={200}
                              height={140}
                              style={{
                                borderRadius: '8px',
                                objectFit: 'contain',
                              }}
                            />
                            <h4>{isAr ? branch.titleAr : branch.titleEn}</h4>
                          </div>
                          <div>
                            <h5>
                              {isAr ? branch.subTitleAr : branch.subTitleEn}
                            </h5>
                            <Link
                              className='fs-6 text-decoration-none text-primary'
                              to={`/BranchDetails/${branch.slugAr}`}
                            >
                              <span>{t('readMore')}</span>
                              <FontAwesomeIcon
                                icon={isAr ? 'arrow-left' : 'arrow-right'}
                                className='ms-2'
                              />
                            </Link>
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                  )
                })}
              </MapContainer>
            </div>
          ) : (
            <div className='col-12 text-center fs-4 fw-bold py-5'>
              {t('noData')}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Map
