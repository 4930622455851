import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getSlider as getSliderReducer,
  getPartners as getPartnersReducer,
  getSocialMedia as getSocialMediaReducer,
} from 'reducers/home.reducer'
import { homeService } from 'services/home.service'

export const getSlider = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getSlider()
    if (response) {
      const sliderData = response

      dispatch(getSliderReducer({ sliderData }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getPartners = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getPartners()
    if (response) {
      const partnersData = response

      dispatch(getPartnersReducer({ partnersData }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}

export const getSocialMedia = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getSocialMedia()
    if (response) {
      const socialMedia = response

      dispatch(getSocialMediaReducer({ socialMedia }))
    }
  } catch (error) {
    console.error(error)
    dispatch(apiFailure())
  }
}
