import React from 'react'
import {
  useAnnualActivities,
  AnnualActivitiesProps,
} from './useAnnualActivities'
import TitleSection from 'components/TitleSection/TitleSection'
import blockContentToHtml from '@sanity/block-content-to-html'
import styles from './AnnualActivities.module.scss'

const AnnualActivities: React.FC<AnnualActivitiesProps> = (
  props: AnnualActivitiesProps
) => {
  const { title, activities } = useAnnualActivities(props)

  return (
    <section className={styles['annual-activities']}>
      <div className='container'>
        <TitleSection title={title} />
        <div className='row'>
          <div
            className='col-12'
            dangerouslySetInnerHTML={{
              __html: Array.isArray(activities)
                ? blockContentToHtml({
                    blocks: activities,
                  })
                : `<p>${activities}</p>`,
            }}
          ></div>
        </div>
      </div>
    </section>
  )
}

export default AnnualActivities
