import React from 'react'
import { useBranchStats, BranchStatsProps } from './useBranchStats'
import TitleSection from 'components/TitleSection/TitleSection'
import styles from './BranchStats.module.scss'

const BranchStats: React.FC<BranchStatsProps> = (props: BranchStatsProps) => {
  const {
    title,
    yearOfFoundationTitle,
    numberOfVolunteersTitle,
    yearOfFoundation,
    numberOfVolunteers,
  } = useBranchStats(props)

  return (
    <section className='py-5'>
      <div className='container'>
        <TitleSection title={title} />
        <div className='row'>
          <div className='col-md-6'>
            <div className={`${styles['stats-item']} text-center w-100 h-100`}>
              <span>{yearOfFoundation}</span>
              <p>{yearOfFoundationTitle}</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className={`${styles['stats-item']} text-center w-100 h-100`}>
              <span>{numberOfVolunteers}</span>
              <p>{numberOfVolunteersTitle}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BranchStats
