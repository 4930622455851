import React from 'react'
import { useTeamListing, TeamListingProps } from './useOurTeamListing'
import TeamCard from 'components/TeamCard/TeamCard'

const OurTeamListing: React.FC<TeamListingProps> = (
  props: TeamListingProps
) => {
  const { ourTeam, isAr, t } = useTeamListing(props)

  return (
    <section>
      <div className='container py-5'>
        <div className='row'>
          {ourTeam && ourTeam.length ? (
            ourTeam.map((member, index) => {
              return (
                <div
                  key={`member-${member.id}-${index}`}
                  className={`col-12 col-md-6 ${ourTeam.length > 3 || window.screen.width < 767 ? 'mb-4' : ''}`}
                >
                  <TeamCard
                    image={member.photo}
                    name={isAr ? member.nameAr : member.nameEn}
                    role={isAr ? member.roleAr : member.roleEn}
                    bio={isAr ? member.bioAr : member.bioEn}
                    socialLinks={member.socialLinks}
                  />
                </div>
              )
            })
          ) : (
            <div className='col-12 text-center fs-4 fw-bold'>{t('noData')}</div>
          )}
        </div>
      </div>
    </section>
  )
}

export default OurTeamListing
