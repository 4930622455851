import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import ISliderResponse from 'models/Home/ISlider'
import IPartnerResponse from 'models/Home/IPartner'
import ISocialMediaResponse from 'models/Home/ISocialMedia'

export const homeService = {
  getSlider: async (): Promise<ISliderResponse> => {
    try {
      const response: ISliderResponse = await makeRequest(
        'GET',
        ENDPOINTS.Home.GetSlider
      )

      return response
    } catch (error) {
      throw new Error(`Get slider data failed: ${error}`)
    }
  },
  getPartners: async (): Promise<IPartnerResponse> => {
    try {
      const response: IPartnerResponse = await makeRequest(
        'GET',
        ENDPOINTS.Home.GetPartners
      )

      return response
    } catch (error) {
      throw new Error(`Get our partners data failed: ${error}`)
    }
  },
  getSocialMedia: async (): Promise<ISocialMediaResponse> => {
    try {
      const response: ISocialMediaResponse = await makeRequest(
        'GET',
        ENDPOINTS.Home.GetSocialMedia
      )

      return response
    } catch (error) {
      throw new Error(`Get social media data failed: ${error}`)
    }
  },
}
