import React from 'react'
import { usePartners, PartnersProps } from './usePartners'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import styles from './Partners.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'

const Partners: React.FC<PartnersProps> = (props: PartnersProps) => {
  const { partnersData, isAr, t } = usePartners(props)

  return (
    <section id='partners' className={styles.partners}>
      <TitleSection title={t('OurPartners')} />
      <Swiper
        dir='rtl'
        loop
        slidesPerView={1}
        spaceBetween={10}
        modules={[Autoplay]}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
          1440: {
            slidesPerView: 7,
            spaceBetween: 50,
          },
        }}
        className={styles.swiper}
      >
        {partnersData &&
          partnersData.length &&
          partnersData.map((partner, index) => (
            <SwiperSlide
              key={`partner-${partner.id}-${index}`}
              className={styles.slide}
            >
              <a
                title={isAr ? partner.nameAr : partner.nameEn}
                href={partner.url}
                target='_blank'
                referrerPolicy='no-referrer'
              >
                <img
                  src={partner.logo}
                  className={styles.image}
                  alt={partner.nameAr}
                />
              </a>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  )
}

export default Partners
