export interface AnnualActivitiesProps {
  title: string
  activities: any
}

export const useAnnualActivities = (props: AnnualActivitiesProps) => {
  return {
    ...props,
  }
}
