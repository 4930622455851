import React, { useEffect } from 'react'
import { useCustomTranslation } from 'hooks/useCustomTranslation'
import OurTeamListing from './OurTeamListing/OurTeamListing'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'

const OurTeam: React.FC = () => {
  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <InnerTitleSection
        title={t('navOurTeam')}
        breadcrumbs={[
          {
            title: t('navHome'),
            link: '/',
          },
          {
            title: t('navOurTeam'),
          },
        ]}
      />
      <OurTeamListing isAr={isAr} t={t} />
    </main>
  )
}

export default OurTeam
