import React, { memo } from 'react'
import { useLatestPost, LatestPostProps } from './useLatestPost'
import styles from './LatestPost.module.scss'
import { Link } from 'react-router-dom'

const LatestPost: React.FC<LatestPostProps> = (props: LatestPostProps) => {
  const { image, title, link, date, isWhite } = useLatestPost(props)

  return (
    <div className={styles['latest-post']}>
      <img
        src={image}
        alt='Latest post media'
        className={`${styles.image} flex-shrink-0`}
      />
      <div>
        <h4 className={styles.title}>
          <Link
            to={link}
            className={`${styles.link} ${isWhite ? styles.white : ''}`}
          >
            {title}
          </Link>
        </h4>
        <time dateTime={date} className={styles.date}>
          {date}
        </time>
      </div>
    </div>
  )
}

export default memo(LatestPost)
