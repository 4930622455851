import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getSocialMedia } from 'actions/home.action'
import { getContactSocials } from 'actions/contact.action'
import { RootState } from 'reducers/rootReducer'
import { useCustomTranslation } from 'hooks/useCustomTranslation'

export interface FooterProps {}

export const useFooter = (props: FooterProps) => {
  const dispatch = useAppDispatch()

  const { t, i18n } = useCustomTranslation()
  const currentLanguage = i18n.language
  const isAr = currentLanguage === 'ar'

  const socialMedia = useSelector((state: RootState) => state.home.socialMedia)
  const contactSocials = useSelector(
    (state: RootState) => state.contact.contactSocials
  )

  useEffect(() => {
    if (!socialMedia) dispatch(getSocialMedia())
  }, [socialMedia])

  useEffect(() => {
    if (!contactSocials) dispatch(getContactSocials())
  }, [contactSocials])

  return {
    ...props,
    isAr,
    socialMedia,
    contactSocials,
    t,
  }
}
