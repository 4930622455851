import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import IContactSocialsResponse from 'models/Contact/IContactSocials'
import { IContactMutation } from 'models/Contact/IContact'

export const contactService = {
  getContactSocials: async (): Promise<IContactSocialsResponse> => {
    try {
      const response: IContactSocialsResponse = await makeRequest(
        'GET',
        ENDPOINTS.Contact.GetContactSocials
      )

      return response
    } catch (error) {
      throw new Error(`Get contact socials data failed: ${error}`)
    }
  },
  sendContactMessage: async (contactInfo: IContactMutation): Promise<any> => {
    try {
      const response: any = await makeRequest(
        'POST',
        ENDPOINTS.Contact.Post,
        contactInfo
      )

      return response
    } catch (error) {
      console.error(error)
      throw new Error('Send contact message failed')
    }
  },
}
