export interface ServiceCardProps {
  title: string
  description: any
  icon: string
  iconPrefix: string
}

export const useServiceCard = (props: ServiceCardProps) => {
  return {
    ...props,
  }
}
