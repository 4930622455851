import makeRequest from 'utils/makeRequest'
import { ENDPOINTS } from 'config/apiEndpoints'
import IAboutResponse from 'models/About/IAbout'

export const aboutService = {
  getAbout: async (): Promise<IAboutResponse> => {
    try {
      const response: IAboutResponse = await makeRequest(
        'GET',
        ENDPOINTS.About.GetAbout
      )

      return response
    } catch (error) {
      throw new Error(`Get about data failed: ${error}`)
    }
  },
}
