export interface AuthorCardProps {
  title: string
  name: string
  description: string
  url: string | null
  photo: string | null
  isAr: boolean
}

export const useAuthorCard = (props: AuthorCardProps) => {
  return {
    ...props,
  }
}
