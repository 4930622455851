import React from 'react'
import { useBranchTitle, BranchTitleProps } from './useBranchTitle'
import styles from './BranchTitle.module.scss'

const BranchTitle: React.FC<BranchTitleProps> = (props: BranchTitleProps) => {
  const { title, subTitle } = useBranchTitle(props)

  return (
    <section className='pt-5'>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-12'>
            <h1 className={styles.title}>{title}</h1>
            <h4>{subTitle}</h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BranchTitle
